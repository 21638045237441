import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader.js'
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer'
import * as dat from 'lil-gui'
import { AnimationMixer, BoxGeometry, NormalAnimationBlendMode, Vector3 } from 'three'
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'
import {Text} from 'troika-three-text'



//____________________________GLOBAL VARIABLES________________________________________

var clickCounter = 0


var defaultLook = true
const backgroundColor1 = 0xfff7e7
const backgroundColor2 = 0x000000

const gltfLoader = new GLTFLoader()
const fbxLoader = new FBXLoader()
const anim = new FBXLoader()
const fontLoader = new FontLoader()
var currentText;
var loadedFont;
var partyVideo = document.getElementById( 'video' );




//____________________________SCENE-SETUP________________________________________


// Canvas
const canvas = document.querySelector('canvas.webgl')


// BG Color
const bgColor = new THREE.Color( 0xd4c8b8 )


// Scene
const scene = new THREE.Scene()
const cssScene = new THREE.Scene()

const fog = new THREE.Fog(0xfff7e7, 2, 15,)
scene.fog = fog

let showSmoke = false
let smokeTime = 0
let mixer = null
let action = null

// Sizes

const sizes = {
  width: window.innerWidth,
  height: window.innerHeight
}

window.addEventListener('resize', () => {
  // Update sizes
  sizes.width = window.innerWidth
  sizes.height = window.innerHeight

  // Update camera
  camera.aspect = sizes.width / sizes.height
  camera.updateProjectionMatrix()

  // Update renderer
  renderer.setSize(sizes.width, sizes.height)
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

})


//Camera

const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(-2.94, 0.89, 2.1)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0,0.95,0.552)
controls.enableDamping = true
controls.maxPolarAngle = Math.PI / 2;
console.log(controls)
controls.maxAzimuthAngle = Math.PI / 1.7
controls.minAzimuthAngle = - Math.PI / 1.7
controls.minZoom = 1
controls.maxZoom = 1.2


// Renderer

const renderer = new THREE.WebGLRenderer({
  antialiasing: true,
  canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.gammaOutput = true
renderer.gammaFactor = 2.2
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(backgroundColor1)
renderer.sortObjects = false


// Lights

const ambientLight = new THREE.AmbientLight(0xffffff, 0.8)
ambientLight.intensity = 0.8

scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 1)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(5, 5, 5)
directionalLight.intensity = 0.6
scene.add(directionalLight)



//____________________________ADDING-COUNTDOWN-TEXT________________________________________


// // Adding Textgeometry

// fontLoader.load(
// '/fonts/Pocket Calculator_Regular.json',
// (font) => {
//   // console.log('font_loaded')
//   loadedFont = font
//   let textGeometry = new TextGeometry(
//   '',
//   {
//     font: font,
//     size: 0.05,
//     height: 0.0001,
//     curveSegments: 1,
//     height:0.0001,
   
//   }
//   )
//  textGeometry.center()
//   const textMaterial = new THREE.MeshBasicMaterial({
//     color: 0xff5900,
//     reflectivity:0
//   })
//   const text = new THREE.Mesh(textGeometry, textMaterial)
//   currentText = text
//   text.position.set(0.85, 0.85, - 0.32)
//   scene.add(text)
// })

// // Refreshing Text-Geometry Function


// function refreshText(nextText) {
//   let textGeometry = new TextGeometry(
//     nextText,
//     {
//       font: loadedFont,
//       size: 0.05,
//       height: 0.0001,
//       curveSegments: 1,
//       height:0.0001,
 
//     }
//     )
//    textGeometry.center()
//     const textMaterial = new THREE.MeshBasicMaterial({
//       color: 0xff5900,
//       reflectivity:0
//     })
//     scene.remove(currentText)
//     const text = new THREE.Mesh(textGeometry, textMaterial)
//     currentText = text
//     text.position.set(0.85, 0.85, - 0.32)
    
//     scene.add(text)

// }


//____________________________LOADING MODELS________________________________________
 

// GLTF Loader Manschgerl

gltfLoader.load(
    '/models/WrodowMan/WomanCompTest3.glb', (gltf) => {
        gltf.scene.traverse(c => {
            c.frustumCulled = false; 
            c.castShadow = true;
          });
    
     mixer = new THREE.AnimationMixer(gltf.scene)
        action = mixer.clipAction(gltf.animations[0])

        action.setLoop( THREE.LoopOnce );

        gltf.scene.scale.set(0.6, 0.6, 0.6,)
        // gltf.scene.position.x = (1)
        gltf.scene.position.z = (0.5)
        // gltf.renderOrder = 10
        scene.add(gltf.scene)
    }
)


// GTLF Haltestelle

gltfLoader.load(
    '/models/Haltestelle/Haltestelle2.glb', (gltf) => {
        gltf.scene.traverse(c => { c.castShadow = true; });
        gltf.scene.scale.set(0.25, 0.25, 0.25,)
        gltf.depthWrite = false
        scene.add(gltf.scene)
    }
)




//____________________________SMOKE-ANIMATION-TRIGGER________________________________________


let button = document.getElementById('SmokeButton')
let partyButton = document.getElementById('partyButton')


function onDocumentMouseDown( event ) {

    if ( action !== null ) {
      action.stop();
      smokeTime = 7;
      action.play();
      clickCounter += 1
      console.log(clickCounter)
      if (clickCounter >= 3) {
        partyButton.style.display = "block"
      }}}

  button.onclick = onDocumentMouseDown




//____________________________BASIC-MESHES________________________________________


/**
 * Floor
 */
const floor = new THREE.Mesh(
    new THREE.PlaneGeometry(50, 50),
    new THREE.MeshStandardMaterial({
        color: bgColor,
        metalness: 0,
        roughness: 1,
    })
)
floor.receiveShadow = true
floor.rotation.x = - Math.PI * 0.5
scene.add(floor)

// Street

const street = new THREE.Mesh(
    new THREE.PlaneGeometry(100, 2),
    new THREE.MeshStandardMaterial({
        color: 0xffffff,
        metalness: 0,
        roughness: 1,
    })
)
street.receiveShadow = true
street.position.y = 0.01
street.position.z = 2
street.rotation.x = - Math.PI * 0.5
scene.add(street)
street.material.transparent = true
street.material.opacity = 0.5

// Window Plane with Glass Material

const windowPlane = new THREE.Mesh(
  new THREE.BoxGeometry (0.725,1.09,0.013),
  new THREE.MeshPhysicalMaterial({
    color: 0xffffff,
    metalness: 0,
    roughness: 0,
    transparent: true,
    envMapIntensity: 0.9,
    clearcoat: 1,
    transmission: .95,
    opacity: 1,
    reflectivity: 0.9,
    refractionRatio: 0.98
})
)
scene.add(windowPlane)
windowPlane.position.set(1.23,0.61,0.0225)
windowPlane.rotation.y = Math.PI / 2
windowPlane.material.depthWrite = false


//____________________________SDF TEXT________________________________________

// Countdown

const countText = new Text()
scene.add(countText)
countText.position.set(0.55, 0.83, - 0.32)

// Set properties to configure:
countText.text = 'Hello world!'
countText.fontSize = 0.06
countText.color = 0xbd4200
countText.depthOffset = 0
countText.font = '/fonts/pocket_calcuatlor_tt.ttf'


// Linentext

const infoText = new Text()
scene.add(infoText)
infoText.position.set(0.55, 0.905, - 0.32)

// Set properties to configure:
infoText.text = '268  Wrodow                 ---'
infoText.fontSize = 0.06
infoText.color = 0xbd4200
infoText.depthOffset = 0
infoText.font = '/fonts/pocket_calcuatlor_tt.ttf'




//____________________________COUNTDOWN SETUP________________________________________


// Adding Countdown Function

var countDownDate = new Date("Aug 26, 2022 14:00:00").getTime();

 // Update the count down every 1 second
 var x = setInterval(function() {
 
   // Get today's date and time
   var now = new Date().getTime();
     
   // Find the distance between now and the count down date
   var distance = countDownDate - now;
     
   // Time calculations for days, hours, minutes and seconds
   var days = Math.floor(distance / (1000 * 60 * 60 * 24));
   var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
   var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
   var seconds = Math.floor((distance % (1000 * 60)) / 1000);
     
   // Output the result in an element with id="demo"
   var countDown = days + "d " + hours + "h "
   + minutes + "m " + seconds + "s ";
     
   countText.text = countDown
 }, 1000);





//____________________________VIDEO+DISKOMODE________________________________________



//Create your video Texture + Material:

const videoTexture = new THREE.VideoTexture(partyVideo);
const videoMaterial =  new THREE.MeshBasicMaterial( {
  map: videoTexture, 
  side: THREE.FrontSide, 
  toneMapped: false,
  transparent: true,
  opacity: 0
} );

//Create videoScreen to map material and texture to

const screen = new THREE.PlaneGeometry(6.4*1.5, 3.52*1.5);
const videoScreen = new THREE.Mesh(screen, videoMaterial);
videoScreen.position.y = 2.5
videoScreen.position.z = -2
scene.add(videoScreen);

// Functions for altering the scene for Disco Mode

function showVideoScreen(event) {
  videoScreen.visible = true
  videoMaterial.transparent = false
  renderer.setClearColor(backgroundColor2)
  console.log(floor.material.color)
  street.material.color.setHex(0x000000)
  floor.material.color.setHex(0x000000)
  fog.color.setHex(0xff0000)
};

function showNormal (event) {
  videoMaterial.transparent = true
  renderer.setClearColor(backgroundColor1)
  street.material.color.setHex(0xffffff)
  floor.material.color.set(bgColor)
  fog.color.setHex(0xfff7e7)
  videoScreen.visible = false
}


// Video Animation Trigger


function onpbClick () {
    partyVideo.play()
    showVideoScreen()
    smokeTime = 7
}

partyButton.onclick = onpbClick
partyVideo.onended = showNormal


//____________________________PARTICLE-SYSTEM________________________________________


// Particle Vertex Shader
const _VS = `
uniform float pointMultiplier;
attribute float size;
attribute float angle;
attribute vec4 colour;
varying vec4 vColour;
varying vec2 vAngle;
void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
  gl_Position = projectionMatrix * mvPosition;
  gl_PointSize = size * pointMultiplier / gl_Position.w;
  vAngle = vec2(cos(angle), sin(angle));
  vColour = colour;
}`;

// Particle Fragment Shader

const _FS = `
uniform sampler2D diffuseTexture;
varying vec4 vColour;
varying vec2 vAngle;
void main() {
  vec2 coords = (gl_PointCoord - 0.5) * mat2(vAngle.x, vAngle.y, -vAngle.y, vAngle.x) + 0.5;
  gl_FragColor = texture2D(diffuseTexture, coords) * vColour;
}`;

// Linear Spline (to create points along line that define particles)

class LinearSpline {
  constructor(lerp) {
    this._points = [];
    this._lerp = lerp;
  }

  AddPoint(t, d) {
    this._points.push([t, d]);
  }

  Get(t) {
    let p1 = 0;

    for (let i = 0; i < this._points.length; i++) {
      if (this._points[i][0] >= t) {
        break;
      }
      p1 = i;
    }

    const p2 = Math.min(this._points.length - 1, p1 + 1);

    if (p1 == p2) {
      return this._points[p1][1];
    }

    return this._lerp(
        (t - this._points[p1][0]) / (
            this._points[p2][0] - this._points[p1][0]),
        this._points[p1][1], this._points[p2][1]);
  }
}

//Particle System Class

class ParticleSystem {
  constructor(params) {
    const uniforms = {
        diffuseTexture: {
            value: new THREE.TextureLoader().load('./resources/cloud.png')
        },
        pointMultiplier: {
            value: window.innerHeight / (1.5 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
        }
    };

    this._material = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: _VS,
        fragmentShader: _FS,
        blending: THREE.AdditiveBlending,
        depthTest: true,
        depthWrite: false,
        transparent: true,
        vertexColors: true
    });

    this._particles = [];
    this._deadParticles = [];
    this._geometry = new THREE.BufferGeometry();
    this._geometry.setAttribute('position', new THREE.Float32BufferAttribute([], 3));
    this._geometry.setAttribute('size', new THREE.Float32BufferAttribute([], 1));
    this._geometry.setAttribute('colour', new THREE.Float32BufferAttribute([], 4));
    this._geometry.setAttribute('angle', new THREE.Float32BufferAttribute([], 1));

    this._points = new THREE.Points(this._geometry, this._material);

    params.parent.add(this._points);

    this._alphaSpline = new LinearSpline((t, a, b) => {
      return a + t * (b - a);
    });
    this._alphaSpline.AddPoint(0.0, 0.0);
    this._alphaSpline.AddPoint(0.05, 0.25);
    this._alphaSpline.AddPoint(0.15, 0.4);
    this._alphaSpline.AddPoint(0.3, 0.0);

    this._colourSpline = new LinearSpline((t, a, b) => {
      const c = a.clone();
      return c.lerp(b, t);
    });
    this._colourSpline.AddPoint(0.0, new THREE.Color(0xFFFFFF));
    this._colourSpline.AddPoint(1.0, new THREE.Color(0xFFFFFF));

    this._sizeSpline = new LinearSpline((t, a, b) => {
      return a + t * (b - a);
    });
    this._sizeSpline.AddPoint(0.0, 0.01);
    this._sizeSpline.AddPoint(0.1, 0.4);
    this._sizeSpline.AddPoint(0.4, 1);

    // document.addEventListener('keydown', (e) => this._onKeyUp(e), false);
  
    this._UpdateGeometry();
  }k

//   _onKeyUp(event) {
//     switch(event.keyCode) {
//       case 32: // SPACE
//         this._AddParticles();
//         break;
//     }
//   }

  _AddParticles(timeElapsed) {
    if (!this.gdfsghk) {
      this.gdfsghk = 1;
    }
    this.gdfsghk += timeElapsed;
    const n = Math.floor(this.gdfsghk * 130.0);
    this.gdfsghk -= n / 130.0;

    for (let i = 0; i < n; i++) {
      const life = (Math.random() * 0.75 + 0.25) * 2.2;
      this._particles.push({
          position: new THREE.Vector3(
              0,
                (Math.random() * (1 - 0.9) + 0.9),
             (Math.random() * (0.6 - 0.552) + 0.552 )),
          size: (Math.random() * 0.25 + 0.25) * 1.5,
          colour: new THREE.Color(),
          alpha: 1,
          life: life,
          maxLife: life,
          rotation: Math.random() * 2.0 * Math.PI,
          velocity: new THREE.Vector3(0, 0, 3.5),
      });
    }
  }

  _UpdateGeometry() {
    const positions = [];
    const sizes = [];
    const colours = [];
    const angles = [];

    for (let p of this._deadParticles) {
        positions.push(p.position.x, p.position.y, p.position.z);
        colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
        sizes.push(p.currentSize);
        angles.push(p.rotation);
      }
    for (let p of this._particles) {
      positions.push(p.position.x, p.position.y, p.position.z);
      colours.push(p.colour.r, p.colour.g, p.colour.b, p.alpha);
      sizes.push(p.currentSize);
      angles.push(p.rotation);
    }

    this._geometry.setAttribute(
        'position', new THREE.Float32BufferAttribute(positions, 3));
    this._geometry.setAttribute(
        'size', new THREE.Float32BufferAttribute(sizes, 1));
    this._geometry.setAttribute(
        'colour', new THREE.Float32BufferAttribute(colours, 4));
    this._geometry.setAttribute(
        'angle', new THREE.Float32BufferAttribute(angles, 1));
  
    this._geometry.attributes.position.needsUpdate = true;
    this._geometry.attributes.size.needsUpdate = true;
    this._geometry.attributes.colour.needsUpdate = true;
    this._geometry.attributes.angle.needsUpdate = true;
  }

  _UpdateParticles(timeElapsed) {
    for (let p of this._particles) {
      p.life -= timeElapsed;
    }

    this._deadParticles = this._particles.filter(p => {
        return p.life <= 0.0;
      });
    this._particles = this._particles.filter(p => {
      return p.life > 0.0;
    });
    for (let p of this._deadParticles) {
        p.alpha = 0.0;

    };

    for (let p of this._particles) {
      const t = 1.0 - p.life / p.maxLife;

      p.rotation += timeElapsed * 0.5;
      p.alpha = this._alphaSpline.Get(t);
      p.currentSize = p.size * this._sizeSpline.Get(t);
      p.colour.copy(this._colourSpline.Get(t));

      p.position.add(p.velocity.clone().multiplyScalar(timeElapsed));

      const drag = p.velocity.clone();
      drag.multiplyScalar(timeElapsed * 2);
      drag.x = Math.sign(p.velocity.x) * Math.min(Math.abs(drag.x), Math.abs(p.velocity.x));
      drag.y = Math.sign(p.velocity.y) * Math.min(Math.abs(drag.y), Math.abs(p.velocity.y));
      drag.z = Math.sign(p.velocity.z) * Math.min(Math.abs(drag.z), Math.abs(p.velocity.z));
      p.velocity.sub(drag);
    }

    this._particles.sort((a, b) => {
      const d1 = camera.position.distanceTo(a.position);
      const d2 = camera.position.distanceTo(b.position);

      if (d1 > d2) {
        return -1;
      }

      if (d1 < d2) {
        return 1;
      }

      return 0;
    });
  }

  Step(timeElapsed, addNewParticles) {
      if (addNewParticles) {
        this._AddParticles(timeElapsed);
      }
    this._UpdateParticles(timeElapsed);
    this._UpdateGeometry();
  }

  turnOFF () {
    this._geometry.setAttribute(
        'size', new THREE.Float32BufferAttribute(sizes, 1))
  }


}

var particles = new ParticleSystem({
    parent: scene,
    camera: camera,
});





/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () => {
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update Mixer
    if(mixer !== null) {
        mixer.update(deltaTime)
    }
    controls.update()

    // Render
    renderer.render(scene, camera)

    // showSmoke function
    if (smokeTime > 0) {
        if (smokeTime < 3){
            let addNewParticles = smokeTime > 1 ? true : false;
            particles.Step(deltaTime, addNewParticles)}
        smokeTime -= deltaTime;

    } 


    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()




// CSS 3D Try

// const cssRenderer = new CSS3DRenderer()
// cssRenderer.setSize(sizes.width, sizes.height);
// cssRenderer.domElement.style.position = 'absolute';
// cssRenderer.domElement.style.top = 0;
// cssRenderer.domElement.style.zIndex = 1;
// cssRenderer.domElement.style.left = 0;
// cssRenderer.domElement.style.margin = 0;
// cssRenderer.domElement.style.padding = 0;
// renderer.domElement.appendChild(cssRenderer.domElement);

// console.log(renderer.domElement)

// var css3dPosition = new Vector3(1,1,1)


// const htmlPlane = new THREE.Mesh(
//   new THREE.PlaneGeometry(2, 2),
//   new THREE.MeshBasicMaterial({
//       color: 0x000000,
//       opacity: 0,
//       blending: THREE.NoBlending
//   })
// )
// htmlPlane.position.set = css3dPosition
// scene.add(htmlPlane)

// console.log(htmlPlane)


// var html = document.createElement( 'img' );
// html.src = 'images/test.jpeg';
// html.style.zIndex = 4
// cssRenderer.domElement.appendChild(html)

// console.log(html)

// // create the object3d for this element
// var cssObject = new CSS3DObject( html );
// // we reference the same position and rotation 
// cssObject.position.set = css3dPosition
// cssObject.height = 2
// cssObject.width = 2
// // add it to the css scene
// cssScene.add(cssObject);
// console.log(cssObject)

